import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  status: 'idle',
  isDev: process.env.NODE_ENV === 'development',
  user: null,
  isAuthed: false,
  init: true,
  igId: null,
  media: {
    is_video: null,
    media_url: null
  },
  confet: false,
  sad: false,
  hint: false,
  card: null
};

export const userSlice = createSlice({
  name: 'globalObject',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setDevToProd: (state= initialState, action) => {
      state.isDev = action.payload
    },
    setUser: (state= initialState, action) => {
      state.user = action.payload
      state.isAuthed = true
    },
    clearUser: (state = initialState) => {
      state.user = null
      state.isAuthed = false
    },
    setInit: (state, action) => {
      state.init = action.payload
    },
    setIgUser: (state, action) => {
      state.igId = action.payload
    },
    clearIgUser: (state) => {
      state.igId = null
    },
    showMedia: (state, action) => {
      state.media = {
        is_video: action.payload.video, media_url: action.payload.url
      }
    },
    removeMedia: (state) => {
      state.media = initialState.media
    },
    showConfet: (state, action) => {
      state.confet = action.payload || 5
    },
    removeConfet: (state) => {
      state.confet = false
    },
    showSad: (state, action) => {
      let waitTime = action.payload
      if(waitTime < 7) {
        waitTime = 8 + Math.random()
      }
      state.sad = waitTime
    },
    removeSad: (state) => {
      state.sad = false
    },
    newHint: (state) => {
      state.hint = 23
    },
    closeHint: (state) => {
      state.hint = false
    },
    closeCard: (state) => {
      state.card = false
    }
  }
});

export const {
  increment, decrement, incrementByAmount, setDevToProd,
  setUser, clearUser, setIgUser, clearIgUser,
    showMedia, removeMedia, showConfet, removeConfet,
    showSad, removeSad, newHint, closeHint, closeCard
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.globalObject.value

export const checkIfDev = (state) => state.globalObject.isDev

export const getUser = (state) => state.globalObject.user

export const getIsAuthed = (state) => state.globalObject.isAuthed

export const getInstaId = (state) => state.globalObject.igId

export const getReduxMedia = (state) => state.globalObject.media

export const getConfet = (state) => state.globalObject.confet

export const getSadState = (state) => state.globalObject.sad

export const hintState = (state) => state.globalObject.hint

export const cardState = (state) => state.globalObject.card

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default userSlice.reducer;
