import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import {useDispatch} from "react-redux";

export const store = configureStore({
  reducer: {
    globalObject: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()