import {StrictMode} from 'react';
import {render} from 'react-dom';
// import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from "./app/store";
// import {init} from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import {register} from './serviceWorkerRegistration';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// import { datadogRum } from '@datadog/browser-rum';
//
// datadogRum.init({
//   applicationId: '174838ad-2658-469b-a476-ee3ad1b5e8db',
//   clientToken: 'pub297cd7094f536d536ba09ff5fbadceb9',
//   site: 'datadoghq.com',
//   service:'games-react',
//   env:'prod',
//
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sampleRate: 100,
//   premiumSampleRate: 100,
//   trackInteractions: true,
//   defaultPrivacyLevel:'mask-user-input'
// });
//
// datadogRum.startSessionReplayRecording();

// init({
//   dsn: "https://d3f910840d09427c99346dfdc85785a7@o4504210486394880.ingest.sentry.io/4504210487246848",
//   integrations: [new BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1,
// });

render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister()