// import ErrorBoundary from "./Common/Components/ErrorBoundary";
import {lazy, Suspense} from "react";

const HomePromise = import("./Home")
const Home = lazy(() => HomePromise)
// import Home from "./Home";

const StyleComponentPromise = import("./components/StyleComponent")
const StyleComponent = lazy(() => StyleComponentPromise)

function App() {

    return (
      <Suspense
        fallback={<></>}
      >
        <StyleComponent />
        <Home />
      </Suspense>
    )
}

export default App;
